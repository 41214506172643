.confirm-wrapper{
    display: grid;
    grid-template-columns: 1fr 2fr;
    place-items: center;
    height:90vh;
    margin-bottom:30px;    
}

.confirm-wrapper img{
    width:100px;
    height: 100px;
}

.image{
    display:flex;
    flex-direction: column;
    font-size:30px;
    font-weight: 600;
    align-items: center;
    
}

.book-content{
    display: flex;
    flex-direction: column;
    align-items:center;
}

.book-content img{
    width: 350px;
    height: 500px;
}

@media screen and (max-width: 568px) {

    .confirm-wrapper{
        grid-template-columns: 1fr;
        grid-gap:0px;
        height: 70vh;
    }

    .confirm-wrapper img{
        width:50px;
        height: 50px;
    }

    .image{
        align-items: center;
    }

    .book-content img{
        padding-top: 10px;
        width: 250px;
        height: 400px;
    }

}

