@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap');

.bookpage {
    font-family: "Poppins", sans-serif;
}

.bookpage-book-name {
    color: #10495c;
    font-size: 2rem;
    font-weight: 700;        
    margin-top: 60px;
    margin-bottom: 40px;
    text-align: center;
}

.bookpage-flex {
    display: grid;
    grid-template-columns: 1fr 1fr;
    place-items: center;
}

.bookpage-image img{
    width: 350px;
    height: 500px;
}

.bookpage-details {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 50vw;
    height: 60vh;
}

.owner-flex {
    display: flex;
    justify-content: space-between;
}


.bookpage-author-name {
    color: #10495c;
    font-size: 1.7rem;
}

.bookpage-owner-name {
    margin-right: 80px;
}



/* Button */
.button-flex {
    margin-top: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.bookpage-getnow {
    outline: none;
    background-color: #f2ad5f;
    color: white;
    border: 2px solid transparent;
    padding: 12px 25px;
    font-weight: 400;
    line-height: 1.5;
}

.bookpage-getnow:hover {
    cursor: pointer;
    color: black;
    background-color: transparent;
    border: 2px solid black;
    transition: 0.6s all ease-in-out;
}

.get-btn-unauthenticated {
    font-size: 18px;
    font-weight: 600;
}

@media screen and (max-width: 800px) {
    
    .bookpage-book-name {
        color: #10495c;
        font-size: 2rem;
        font-weight: 700;        
        margin-top: 50px;
        margin-bottom: 40px;
        text-align: center;
    }
    
    .bookpage-flex {
        display: grid;
        grid-template-columns: 1fr;
        place-items: center;
    }
    
    .bookpage-image img{
        width: 250px;
        height: 400px;
    }
    
    .bookpage-details {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        width: 80vw;
        height: 60vh;
    }
    
    .owner-flex {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    
    
    .bookpage-author-name {
        color: #10495c;
        font-size: 1.5rem;
    }
    
    .bookpage-owner-name {
        margin-right: 20px;
    }
    
    /* Button */
    .button-flex {
        margin-top: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .bookpage-getnow {
        outline: none;
        background-color: #f2ad5f;
        color: white;
        border: 2px solid transparent;
        padding: 10px 20px;
        font-weight: 300;
        line-height: 1.25;
    }
    
    .bookpage-getnow:hover {
        cursor: pointer;
        color: black;
        background-color: transparent;
        border: 2px solid black;
        transition: 0.6s all ease-in-out;
    }
    
    .get-btn-unauthenticated {
        font-size: 18px;
        font-weight: 600;
    }

}