.NavbarItems{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100vw; 
    height: 100px;
    padding-left: 2em;
    margin: 0px;
    border-radius: 13px;
}

.Navbar_logo{
    color: #008296; 
    font-size: 2rem;
    cursor: pointer;
}

.Nav-logo{
    width: 200px;
}

.Navbar_menu{
    display: flex;
    justify-content: space-around;
    padding-right: 40px;
    align-items: center;
    gap:30px;
}

.user-icon:active{
    transform:scale(0.95);

}

.lend_book{
    cursor:pointer;
}

.Signup{
    display:none;
}

.login{
    color:white;
    background-color: white;
    border: none;
    outline: none;
    position: relative;
    display: inline-block;
}

.login:hover{
    transform:scale(1.05);
}

.lend_book{
    /* background-color: #008296;
    padding:10px;
    color:white;
    border-radius: 10px; */
    background-color: #008296;
    color: white;
    font-size: 16px;
    padding: 12px 24px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
}

.lend_book:active{
    transform:scale(0.95);
}

/* .dropdown-content {
    position: absolute;
    background-color: #008296;
    color:white;
    text-decoration: none;
    min-width: 200px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
  }

  .login:hover .dropdown-content {
    display: block;
    right:30px;
    top:0px;
    margin:1px; 
    border-radius: 20px;
} */

/* .link{
    text-decoration: none;
    padding:0.4em;
    color:white;
} */

.hide{
    display: none;
}


.sub-menu-wrap{
    position:absolute;
    display: block;
    top:12%;
    right: 2%;
    width:275px;
    z-index: 1;
    max-height: 400px;
    overflow:hidden;
    transition: max-height ease-in 1s ;
}

.sub-menu{
    padding: 10px;
    margin:10px;
    background-color: #008296;
}

.user-info{
    display:flex;
    text-align: center;
    justify-content: space-around;
}

.user-info img{
    width:60px;
    border-radius: 50%;
    margin-right:10px;
}

.user-info h2{
    font-weight:500;
}

.sub-menu hr{
    border:0;
    height: 1px;
    width:100%;
    background: #ccc;
    margin:15px 0 0px;
}

.navbar-flex-option{
    display:flex;
    align-items: center;
    margin:12px 0;
    text-decoration: none;
    padding:0.4em;
    color:white;
}

.navbar-flex-option p{
    width: 100%;
}

.navbar-flex-option span{
    font-size: 20px;
    transition: transform 0.5s;
}

.navbar-flex-option:hover{
    transform: scale(1.05);
}

.navbar-flex-option:hover span{
    transform: translateX(3px);
}

.navbar-flex-option:hover p{
    font-weight: 600;
}





@media screen and (max-width: 768px){
    .Navbar_logo{
        color: #008296; 
        font-size: 1rem;
        cursor: pointer;
    }

    .navbar-flex-option{
        display:flex;
        align-items: center;
        margin:12px 0;
        text-decoration: none;
        padding:0.4em;
        color:white;
        font-size: 10px;
    }

    .Nav-logo{
        width: 140px;

    }

    .Navbar_menu{
        grid-gap: 1rem;
        grid-column-end: auto;
        padding: 1rem;
        font-size: 1em;
    }

    .lend_book{
        background-color: #008296;
        color: white;
        font-size: 12px;
        padding:8px 10px;
        border: none;
        cursor: pointer;
        border-radius: 5px;
    }
  }
