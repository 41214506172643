@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap');

ul{
    list-style: none;
  }
  
  .footer_main{
    font-family: "Poppins", sans-serif;
    height: 10rem;
    background-color: #008296;
    color: white;
    display:flex;
    font-size: 0.9rem;
    justify-content: space-between;
    align-items: center;
    padding-left: 50px;
    padding-right:60px;
  }

  .Navbar_logo{
    color: white; 
    font-size: 35px;
    cursor: pointer;
}

  .phone{
    color:white;
    text-decoration: none;
  }
  
  .footer_main ul{
    item-style:none;
  }
  
  .phone_number{
    color:white;
    text-decoration: none;
  }
  
  @media screen and (max-width: 768px){
  
    .Navbar_logo{
      color: white; 
      font-size: 20px;
      cursor: pointer;
  }
  
    .footer_main{
        font-size:0.7em;
        height: 12em;
        background-color: #008296;
        color: white;
        display:flex;
        justify-content: space-between;
        align-items: center;
        padding-left: 20px;
        padding-right:20px;
    }
  }
  