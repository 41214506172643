@import url('https://fonts.googleapis.com/css?family=Poppins:400,500,600,700&display=swap');

.register-book-container {
    font-family: 'Poppins',sans-serif;
    display: flex;
    height:auto;
    justify-content: center;
    align-items: center;
    margin:20px 0 20px 0;
}

.register-book-wrapper{
  width: 500px;
  background:#fff;
  border-radius: 15px;
  box-shadow: 0px 15px 20px rgba(0,0,0,0.1);
} 

.register-book-wrapper .register-book-title{
  font-size: 35px;
  font-weight: 600;
  text-align: center;
  line-height: 100px;
  color:#fff;
  user-select: none;
  border-radius: 15px 15px 0 0;
  /* background:linear-gradient(-135deg, #c850c0, #ff5e78); */
  background:linear-gradient(-135deg, #10495c, #10495c);
}

.register-book-subpart {
    text-align: center;
}

.book-details {
    height: auto;
    /* display: flex; */
    /* flex-direction: column; */
    /* justify-content: space-around; */
}

.register-book-wrapper form{
  padding:10px 30px 50px 30px;
}
.register-book-wrapper form .register-book-field{
  height:50px;
  width:100%;
  margin-top:20px;
  position: relative;
}
.register-book-wrapper form .register-book-field input{
  height:100%;
  width:100%;
  outline:none;
  font-size: 17px;
  padding-left: 20px;
  border:1px solid lightgray;
  border-radius: 25px;
  transition: all 0.3s ease;
}

.register-book-wrapper form .register-book-field input:focus,
form .field input:valid{
  border-color:#10495c;
}
.register-book-wrapper form .register-book-field label{
  position:absolute;
  top:50%;
  left:20px;
  color:#999;
  font-weight: 400;
  font-size: 17px;
  pointer-events: none;
  transform:translateY(-50%);
  transition: all 0.3s ease;
}


.register-book-wrapper form .register-book-field input:focus ~ label,
.register-book-wrapper form .register-book-field input:valid ~ label
{
  top:0%;
  font-size: 16px;
  color:#10495c;
  background:#fff;
  transform:translateY(-50%);
}

.register-book-wrapper form .register-book-field input[type="submit"]{
  color:#fff;
  border:none;
  padding-left:0;
  font-size: 20px;
  font-weight: 500;
  cursor: pointer;
  /* width: 120px; */
  /* margin: 0 auto; */
  margin-top: 20px;
  /* background:linear-gradient(-135deg, #c850c0, #ff5e78); */
  background:linear-gradient(-135deg, #10495c, #10495c);
  transition:all 0.3s ease;
}
.register-book-wrapper form .register-book-field input[type="submit"]:active{
  transform:scale(0.95);
}

.register-book-field-bookCover label {
    margin-top: 40px;
}

.register-book-field-bookCover input {
    margin-top: 40px;
    padding-top: 13px;
}

.register-book-flex {
    display: grid;
    grid-template-columns:1fr;
    gap: 20px;
}

/* Modal Styling */

/* .Modal {
  display: none;
  display: flex;
} */

.modal {
  /* outline: none; */
  color: black;
  background-color: white;
  margin: auto;
  margin-top: 30vh;
  height: 300px;
  width: 500px;
  opacity: 1;
  position: relative;
  border: 2px solid black;
}

.modal-flex {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 270px;
}

.modal-heading {
  color: black;
  font-size: 20px;
  /* position: absolute; */
  margin-top: 50px;
  text-align: center;
}

.modal-close-btn {
  color: black;
  position: absolute;
  top: 20px;
  right: 20px;
}

.modal-desc {
  text-align: center;
}

.modal-close-btn:hover {
  cursor: pointer;
}

.modal-btn {
  background-color: #10495c;
  color: white;
  padding: 12px 25px;
}

.register-book-unauthenticated {
  height: 90vh;
  display: flex;
  align-items: center;
  justify-content: center;
}


@media screen and (max-width: 1000px) {

  
  .register-book-container {
    font-family: 'Poppins',sans-serif;
    display: flex;
    height:70vh;
    justify-content: center;
    align-items: center;
    margin:20px 0 20px 0;
}

  .register-book-flex {
    display: grid;
    grid-template-columns: 1fr;
    gap: 60px;
  }

  .register-book-wrapper{
    width: 50%;
  } 
  
  .register-book-wrapper .register-book-title{
    font-size: 20px;
    line-height: 70px;
  }
  
  .book-details {
      height: auto;
      /* display: flex; */
      /* flex-direction: column; */
      /* justify-content: space-around; */
  }
  
  .register-book-wrapper form{
    padding:10px 30px 50px 30px;
  }

  .register-book-wrapper form .register-book-field{
    height:40px;
    width:100%;
    margin-top:20px;
  }

  .register-book-wrapper form .register-book-field input{
    width:95%;
    font-size: 14px;
  }
  
  .register-book-wrapper form .register-book-field-desc{
      height:100px;
      width: 95%;
      margin-top:20px;
      position: relative;
    }
  
  .register-book-wrapper form .register-book-field textarea {
      font-size: 16px;
  }   
  
  .register-book-wrapper form .register-book-field label{
    font-size: 16px;
  }

  .register-book-wrapper form .register-book-field input:focus ~ label,
  .register-book-wrapper form .register-book-field input:valid ~ label,
  .register-book-wrapper form .register-book-field textarea:focus ~ label,
  .register-book-wrapper form .register-book-field textarea:valid ~ label{
    font-size: 15px;
  }
  
  .register-book-wrapper form .register-book-field input[type="submit"]{
    color:#fff;
    border:none;
    padding-left:0;
    font-size: 18px;
    font-weight: 500;
    cursor: pointer;
    /* width: 120px; */
    /* margin: 0 auto; */
    margin-top: 20px;
    /* background:linear-gradient(-135deg, #c850c0, #ff5e78); */
    background:linear-gradient(-135deg, #10495c, #10495c);
    transition:all 0.3s ease;
  }
  .register-book-wrapper form .register-book-field input[type="submit"]:active{
    transform:scale(0.95);
  }

  .register-book-field-bookCover{
    margin-bottom:40px; 
  }
  
  .register-book-field-bookCover label {
      margin-top: 40px;
  }
  
  .register-book-field-bookCover input {
      margin-top: 40px;
      padding-top: 13px;
  }

}

  
@media screen and (max-width: 700px) {

  .register-book-container {
    font-family: 'Poppins',sans-serif;
    display: flex;
    height:70vh;
    justify-content: center;
    align-items: center;
    margin:20px 0 20px 0;
}

  .register-book-flex {
    display: grid;
    grid-template-columns: 1fr ;
    gap: 60px;
  }

  .register-book-wrapper{
    width: 80%;
  } 
  
  .register-book-wrapper .register-book-title{
    font-size: 20px;
    line-height: 70px;
  }
  
  .book-details {
      height: auto;
      /* display: flex; */
      /* flex-direction: column; */
      /* justify-content: space-around; */
  }
  
  .register-book-wrapper form{
    padding:10px 30px 50px 30px;
  }

  .register-book-wrapper form .register-book-field{
    height:40px;
    width:100%;
    margin-top:20px;
  }

  .register-book-wrapper form .register-book-field input{
    width:95%;
    font-size: 14px;
  }
  
  .register-book-wrapper form .register-book-field-desc{
      height:100px;
      width: 95%;
      margin-top:20px;
      position: relative;
    }
  
  .register-book-wrapper form .register-book-field textarea {
      font-size: 16px;
  }   
  
  .register-book-wrapper form .register-book-field label{
    font-size: 16px;
  }

  .register-book-wrapper form .register-book-field input:focus ~ label,
  .register-book-wrapper form .register-book-field input:valid ~ label,
  .register-book-wrapper form .register-book-field textarea:focus ~ label,
  .register-book-wrapper form .register-book-field textarea:valid ~ label{
    font-size: 15px;
  }
  
  .register-book-wrapper form .register-book-field input[type="submit"]{
    color:#fff;
    border:none;
    padding-left:0;
    font-size: 18px;
    font-weight: 500;
    cursor: pointer;
    /* width: 120px; */
    /* margin: 0 auto; */
    margin-top: 20px;
    /* background:linear-gradient(-135deg, #c850c0, #ff5e78); */
    background:linear-gradient(-135deg, #10495c, #10495c);
    transition:all 0.3s ease;
  }
  .register-book-wrapper form .register-book-field input[type="submit"]:active{
    transform:scale(0.95);
  }

  .register-book-field-bookCover{
    margin-bottom:0px;
  }
  
  .register-book-field-bookCover label {
      margin-top: 40px;
  }
  
  .register-book-field-bookCover input {
      margin-top: 40px;
      padding-top: 13px;
  }
}



