.btn{
  z-index: 1;
}
@media only screen and (max-width: 767px) {
  .btn {
    font-size: 8px; /* Adjust the font size for mobile view */
    /* Add any other styles for mobile view here */
  }
}
 .container {
    position: relative;
    width: 100%;
    /* z-index: -1; */
  }
  
  .container img {
    width: 100%;
    height: auto;
  }
  
  .container .btn {
    position: absolute;
    top: 70%;
    left: 20%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    background-color: #008296;
    color: white;
    font-size: 16px;
    padding: 12px 24px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
  }
  
  /* .btn:hover {
    transform: scale(0.95);
  } */

/* @media only screen and (max-width: 750px) {
    .container .btn {
      font-size: 0.75em;
      padding:1em;
      border: none;
      cursor: pointer;
      border-radius: 0.5em;
      text-align: center;
    }
    
   } */

   /* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .container .btn {
        font-size: 0.5em;
        padding:1em;
        border: none;
        cursor: pointer;
        border-radius: 0.5em;
        text-align: center;
      }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
/* @media only screen and (min-width: 600px) {} */

/* Medium devices (landscape tablets, 768px and up) */
/* @media only screen and (min-width: 768px) {} */

/* Large devices (laptops/desktops, 992px and up) */
/* @media only screen and (min-width: 992px) {} */

/* Extra large devices (large laptops and desktops, 1200px and up) */
/* @media only screen and (min-width: 1200px) {}  */
