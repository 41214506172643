@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap');

* {
  font-family: "Poppins" , sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  box-sizing: border-box;
}

